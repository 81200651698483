import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const Services = () => (
    <>
      <section className="our-service-provide section-padding theme-bg">
        <div className="container">
          <div className="col-12 col-lg-12 mb-40">
            <div className="block-contents text-center">
              <div className="section-title">
                {/* <h5>Mega</h5> */}
                {/* <span><FormattedMessage id="home--features-subtitle" /></span> */}
                <h2>
                  Mega <span><FormattedMessage id="services--title" /></span>
                </h2>
              </div>
            </div>
          </div>

          <div className="row text-center">
            <div className="col-xl-4 col-md-6 col-12" >
              <div className="single-our-service" style={{height:'94%'}}>
                <div
                  className="thumb bg-cover"
                  style={{
                    backgroundImage: "url('/img/shelves.jpg')",
                  }}
                ></div>
                <div className="content" style={{border:'none'}}>
                  {/* <div className="icon">
                    <i className="flaticon-airplane-5"></i>
                  </div> */}
                  <h3>
                    <LinkTranslated href="/storage"><FormattedMessage id="services--1-title" /></LinkTranslated>
                  </h3>
                  <p><FormattedMessage id="services--1-desc" /></p>
                </div>
              </div>
            </div>
            {/* <div className="col-xl-3 col-md-6 col-12">
              <div className="single-our-service">
                <div
                  className="thumb bg-cover"
                  style={{
                    backgroundImage: "url('assets/img/services/ship.jpg')",
                  }}
                ></div>
                <div className="content">
                  <div className="icon">
                    <i className="flaticon-ship-5"></i>
                  </div>
                  <h3>
                    <a href="services-details.html"><FormattedMessage id="services--2-title" /></a>
                  </h3>
                  <p><FormattedMessage id="services--2-desc" /></p>
                </div>
              </div>
            </div> */}
            <div className="col-xl-4 col-md-6 col-12" >
              <div className="single-our-service" style={{height:'94%'}}>
                <div
                  className="thumb bg-cover"
                  style={{
                    backgroundImage: "url('/img/folders.jpg')",
                  }}
                ></div>
                <div className="content" style={{border:'none'}}>
                  {/* <div className="icon">
                    <i className="flaticon-truck-2"></i>
                  </div> */}
                  <h3>
                    <LinkTranslated href="/disposal"><FormattedMessage id="services--3-title" /></LinkTranslated>
                  </h3>
                  <p><FormattedMessage id="services--3-desc" /></p>
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-6 col-12" >
              <div className="single-our-service" style={{height:'94%'}}>
                <div
                  className="thumb bg-cover"
                  style={{
                    backgroundImage: "url('/img/boxes.jpg')",
                  }}
                ></div>
                <div className="content" style={{border:'none'}}>
                  {/* <div className="icon">
                    <i className="flaticon-subway"></i>
                  </div> */}
                  <h3>
                    <LinkTranslated href="/cartons"><FormattedMessage id="services--4-title" /></LinkTranslated>
                  </h3>
                  <p><FormattedMessage id="services--4-desc" /></p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );