import * as React from "react";
import Layout from "../components/layout";
// import * as sections from "../components/sections"
// import Fallback from "../components/fallback"
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../LinkTranslated";
import SimpleLocalize from "../SimpleLocalize";

//sections
import { ValueProposition } from "../components/sections/ValueProposition";
import { Banner } from "../components/sections/Banner";
import { PriceBanner } from "../components/sections/PriceBanner";
import { HowWeWork } from "../components/sections/HowWeWork";
import { PriceTable } from "../components/sections/PriceTable";
import { Team } from "../components/sections/Team";
import { Faq } from "../components/sections/Faq";
import { VideoBanner } from "../components/sections/VideoBanner";
import { Clients } from "../components/sections/Clients";
import { Cta } from "../components/sections/Cta";
import { ServiceFeatures } from "../components/sections/ServiceFeatures";
import { Services } from "../components/sections/Services";
import { Certification } from "../components/sections/certification";

export default function Homepage(props) {

  // const DocumentTitle = (id) => (
  //   <FormattedMessage id='meta--title' defaultMessage='404'>
  //     {(message) => {
  //       if (document.title !== message) {
  //         document.title = message
  //       }
        
  //       return null
  //     }}
  //   </FormattedMessage>
  // )
  return (
    
    <SimpleLocalize {...props}>
      <Layout>
        {/* <DocumentTitle/> */}
        {/* carousel */}
        <Banner /> 
        {/* 4 boxes white */}
        
        <ServiceFeatures/> 
        {/* left image right title descriptions */}
        {/* <ValueProposition />  */}
        {/* 4 boxes blue gradients */}
        <Services /> 
        {/* image title cta */}
        {/* <PriceBanner />  */}
        {/* 4 circles images */}
        <HowWeWork /> 
        {/* verticle table */}
        <PriceTable /> 
        {/* 4 boxes roll over */}
        {/* <Team />  */}
        {/* expand and image */}
        {/* <Faq />  */}
        {/* 2 rows logos */}
        <Certification/>
        <Clients />
        
        <Cta /> {/* text background cta */}
      </Layout>
    </SimpleLocalize>
  );
}
