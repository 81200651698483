import * as React from "react";

//translation
import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const Banner = () => (
    <>
      <section className="hero-slide-wrapper hero-3">
        <div className="hero-slider-3 megastorage-dots">
          {/* banner 1 */}
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: "url('/img/slide1.jpg')" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-12 mt-5 mt-lg-0 order-2 order-lg-1 text-center text-lg-start">
                  <div className="hero-contents text-white">
                    <p
                      className="animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.04"
                    >
                      <FormattedMessage id="home--banner1-title" />
                    </p>
                    <h1
                      className="animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.3"
                    >
                      <FormattedMessage id="home--banner1-desc-1" /><br/>
                      <FormattedMessage id="home--banner1-desc-2" />
                    </h1>
                    <a
                      href="/storage"
                      className="theme-btn animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.9"
                    >
                      <FormattedMessage id="home--banner1-cta" /> <i className="fas fa-arrow-right"></i>
                    </a>
                    {/* <a
                      href="about.html"
                      className="theme-btn minimal-btn animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.6"
                    >
                      learn more <i className="fas fa-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                {/* video */}
                {/* <div className="col-lg-3 col-12 order-1 order-lg-2 text-center text-lg-end">
                  <div
                    className="video-play-btn animated"
                    data-animation-in="fadeInUp"
                    data-delay-in="0.8"
                  >
                    <a
                      href="https://www.youtube.com/watch?v=E1xkXZs0cAQ"
                      className="play-video popup-video"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          {/* banner 2 */}
          <div
            className="single-slide bg-cover"
            style={{ backgroundImage: "url('/img/slide3.jpg')" }}
          >
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-lg-12 mt-5 mt-lg-0 order-2 order-lg-1 text-center text-lg-start">
                  <div className="hero-contents text-white">
                    <p
                      className="animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.04"
                    >
                      <FormattedMessage id="home--banner2-title" />
                    </p>
                    <h1
                      className="animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.3"
                    >
                      <FormattedMessage id="home--banner2-desc-1" /><br/>
                      <FormattedMessage id="home--banner2-desc-2" />
                    </h1>
                    <a
                      href="/storage"
                      className="theme-btn animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.9"
                    >
                      <FormattedMessage id="home--banner2-cta" /> <i className="fas fa-arrow-right"></i>
                    </a>
                    {/* <a
                      href="about.html"
                      className="theme-btn minimal-btn animated"
                      data-animation-in="fadeInLeft"
                      data-delay-in="0.6"
                    >
                      learn more <i className="fas fa-arrow-right"></i>
                    </a> */}
                  </div>
                </div>
                {/* video */}
                {/* <div className="col-lg-3 col-12 order-1 order-lg-2 text-center text-lg-end">
                  <div
                    className="video-play-btn animated"
                    data-animation-in="fadeInUp"
                    data-delay-in="0.8"
                  >
                    <a
                      href="https://www.youtube.com/watch?v=E1xkXZs0cAQ"
                      className="play-video popup-video"
                    >
                      <i className="fas fa-play"></i>
                    </a>
                  </div>
                </div> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );