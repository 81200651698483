import * as React from "react";

import { FormattedMessage } from "react-intl";
import LinkTranslated from "../../LinkTranslated";

export const Certification = () => (
    <>
    <section class="about-us-wrapper" style={{padding: '40px'}}>
        <div class="container" style={{display:'flex', alignItems:'center', justifyContent:'center'}}>
            <div class="row"
            style={{display:'flex', flexDirection:'row', columnGap:'40px'}}>
                <div class="col-lg-6 text-center text-lg-start" style={{display:'flex', justifyContent:'center', alignItems:'center'}}>
                    <div class="about-img" style={{display:'flex', alignItems:'center', justifyContent:'center', maxWidth:'300px'}}>
                        <img src="/img/ISO9001-2015.jpg" alt=""/>
                    </div>
                </div>
                <div class="col-lg-5" style={{display:'flex', justifyContent:'center', alignItems:'center', paddingBottom:'20px'}}>
                    <div class="block-contents">
                        <div class="section-title">
                            <div style={{fontSize:'30px', color:'#1f425d'}}><br/>
                            <FormattedMessage id="cert--1-1"  /><br/>
                                
                            <span style={{color:'#4ab9cf'}}><FormattedMessage id="cert--1-2" /></span><br/>
                            <span style={{color:'#4ab9cf'}}><FormattedMessage id="cert--1-3"/></span><br/>
                                <FormattedMessage id="cert--1-4" style={{color:'#1f425d'}} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    </>
  )