import * as React from "react";

import { FormattedMessage } from "react-intl";

export const Clients = () => (
  <>
    <section
          className="brand-showcase-wrapper section-padding bg-contain bg-center"
          style={{ backgroundImage: "url('/img/dot_map.png')" }}
        >
          <div className="container">
            <div className="col-md-12">
              <div className="block-contents mb-60">
                <div className="section-title text-center">
                  <h5 className="text-center">Mega</h5>
                  <span><FormattedMessage id="clients--subtitle" /></span>
                  <h2>
                  <FormattedMessage id="clients--title1" /><span><FormattedMessage id="clients--title2" /></span>
                  </h2>
                </div>
              </div>
            </div>

            <div className="row d-flex justify-content-center align-items-center">
              <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo" style={{backgroundImage:'url(/img/brand/spotlight.jpg)', backgroundPosition:'center', backgroundSize:'cover'}}>
                  {/* <img src="/img/brand/FUJITSU.jpg" /> */}
                  <FormattedMessage id="client--1" />
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo" style={{backgroundImage:'url(/img/brand/spotlight.jpg)', backgroundPosition:'center', backgroundSize:'cover'}}>
                  <FormattedMessage id="client--2" />
                  {/* <img src="/img/brand/LEVIS.jpg" alt="megastorage" /> */}
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo" style={{backgroundImage:'url(/img/brand/spotlight.jpg)', backgroundPosition:'center', backgroundSize:'cover'}}>
                <FormattedMessage id="client--3" />
                  {/* <img src="/img/brand/AH.jpg" alt="megastorage" /> */}
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo" style={{backgroundImage:'url(/img/brand/spotlight.jpg)', backgroundPosition:'center', backgroundSize:'cover'}}>
                <FormattedMessage id="client--4" />
                  {/* <img src="/img/brand/CCI.jpg" alt="megastorage" /> */}
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo" style={{backgroundImage:'url(/img/brand/spotlight.jpg)', backgroundPosition:'center', backgroundSize:'cover'}}>
                <FormattedMessage id="client--5" />
                  {/* <img src="/img/brand/HWL.jpg" alt="megastorage" /> */}
                </div>
              </div>
              <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo" style={{backgroundImage:'url(/img/brand/spotlight.jpg)', backgroundPosition:'center', backgroundSize:'cover'}}>
                <FormattedMessage id="client--6" />
                  {/* <img src="/img/brand/YXS.jpg" alt="megastorage" /> */}
                </div>
              </div>
              {/* <div className="col-md-4 col-lg-3 col-12">
                <div className="single-brand-logo">
                  <img src="/img/brand/7.png" alt="megastorage" />
                </div>
              </div> */}
            </div>
          </div>
        </section>
  </>
);
